<div class="overlay" (click)="onClickOut($event)">
  <div class="overlay-body p-rel">
    @if (title) { <h2 class="h3 overlay-title">{{ title }}</h2> }
    <div class="overlay-content">
      <ng-content></ng-content>
    </div>
    @if (dismissable) {
      <div class="overlay-close">
        <ng-content select="[close]"></ng-content>
        <span class="link" (click)="onClose($event)">{{ closeText }}</span>
      </div>
    }
  </div>
</div>
