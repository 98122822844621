<div class="sky-card">
  <div class="sky-card-header">
    <h1 class="h2 mb-0">{{ rs.name }}</h1>
  </div>
  <div class="sky-card-body">
    <div class="grid grid-2">
      <!-- Wiki -->
      <app-wiki-link [wiki]="rs._wiki" [aClass]="'container' + (!rs._calendar ? ' colspan-2' : '')"></app-wiki-link>
      <!-- Calendar -->
      <app-calendar-link [cal]="rs._calendar" [aClass]="'container'"></app-calendar-link>
      <!-- Date -->
      <div class="container" [class.expired]="state === 'ended'">
        <mat-icon class="menu-icon">calendar_month</mat-icon>
        <span class="menu-label">
          <app-date [date]="rs.date"></app-date>
          to
          <app-date [date]="rs.endDate"></app-date>
        </span>
        <span *ngIf="state === 'future'" class="c-accent">
          (<app-days-left [start]="rs.date" [end]="rs.endDate"></app-days-left>)
        </span>
      </div>
      <!-- Duration -->
      <div class="container" [class.expired]="state === 'ended'">
        <mat-icon class="menu-icon">schedule</mat-icon>
        <span class="menu-label">
          <app-duration [start]="rs.date" [end]="rs.endDate"></app-duration>
          <span *ngIf="state === 'active'" class="c-accent">
            (<app-days-left [start]="rs.date" [end]="rs.endDate"></app-days-left>)
          </span>
        </span>
      </div>
      <!-- Realm -->
      <a class="container" *ngIf="rs.area?.realm" [routerLink]="['/realm', rs.area!.realm!.guid]">
        <mat-icon class="menu-icon">map</mat-icon>
        <span class="menu-label">
          {{ rs.area!.realm!.name }}
        </span>
      </a>
      <!-- Area -->
      <div class="container" *ngIf="rs.area">
        <mat-icon class="menu-icon">location_on</mat-icon>
        <span class="menu-label">
          {{ rs.area!.name }}
        </span>
      </div>
      <!-- Draft -->
      @if (rs.draft) {
        <div class="container colspan-2">
          <mat-icon class="menu-icon">warning</mat-icon>
          <span class="menu-label">
            This data has not yet been checked on the live version of Sky. Data may be incomplete or inaccurate.
          </span>
        </div>
      }
    </div>
  </div>
</div>

<!-- Spirits -->
<div class="sky-card mt">
  <div class="sky-card-header">
    <h2 class="h3">Spirits</h2>
  </div>
  <div class="sky-card-body pad-0">
    <div class="sky-flex scroll-x pad">
      <!-- Spirit trees -->
      <ng-container *ngIf="rs.spirits?.length">
        <ng-container *ngFor="let spirit of rs.spirits;">
          <div class="tree-wrapper" *ngIf="spirit.tree">
            <app-spirit-tree [tree]="spirit.tree" [name]="spirit.spirit.name">
              <!-- Link to spirit -->
              <div name>
                <a [routerLink]="['/spirit', spirit.spirit.guid]" [queryParams]="{ highlightTree: spirit.tree.guid }">
                  {{ spirit.spirit.name }}
                </a>
              </div>
            </app-spirit-tree>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
